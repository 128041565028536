html {
  height: 100%;
}
body {
  min-height: 100%
}
#root {
    min-height: 100vh;
}
input[type="tel"]{
  border: 1px solid #FF40D7;
}
body{
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	font-size:90% !important;
	color: #000E4D !important;
}
.login{
  background-repeat: no-repeat;
  background-position: center center fixed;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mujer{
  right:-110px;
  bottom: -25px;
  width: 120%;
}


form .form-control2  {
    margin-top: 10px !important;
    background-color: #FF40D7 !important;
    color: white !important;
    border: 1px solid #FF40D7 !important;
    border-radius: 25px;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9375rem !important;
    font-weight: 400 !important;
    display: block;
    width: 100%;
    padding: 10px;
    box-shadow: 1px 2px 2px 0 rgba(0, 0, 0, 0.16) !important;
    margin-bottom: 0;
}


.input-group {
    border: 2px solid #586F89;
    border-radius: 25px;
}

.input-group input {
    padding-left: 0 !important;
    border: 0 !important;
    background: transparent !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
}

.align-items-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.input-group-addon {
    width: 40px;
    text-align: center;
    padding: 0;
    background: transparent;
    border: none;
    border-radius: 0;
}

a.contrasena {
    color: #506883;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    margin-top: 0;
    padding: 0 10px;
    outline: none;
    text-decoration: none;
    box-shadow: none;
}

.sidebar{
  background-color: #333333;
  color: rgba(255, 255, 255, 0.5);
}


.navbar-default .nav,
.navbar-default .collapse,
.navbar-default .dropup,
.navbar-default .dropdown {
    position: static;
    width: 100%;
    left: 0;
    padding: 0;
}

.navbar-default .navbar-nav > li.show > a,
.navbar-default .navbar-nav > li.active {
    background-color: #FF40D7;
    color: white !important;
}

.navbar-default .navbar-nav > li > a {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 10px 20px;
    text-decoration: none;
}

/*.dropdown-menu li {
    background-color: #333333;       Se comenta porque cambia el color del menu no se que otro elemento afecte por favor informar para dar solucion
}*/

.ttmenu-content {
    padding: 40px;
    color: white;
}

.navtop{
  background-color: #F5F5F5;
}

.bg-gray{
  background-color: #f5f5f5;
}

.bg-gray2{
  background-color: #F9F9F9;
}

.bg-white{
  background-color: #fff;
}

.top-0{
  top: 0;
  left: 0;
}

.title-form > h1 {
    font-size: 1.34em;
    font-weight: 740;
    color: #3E3E3E !important;
}

.subtitle-forms {
    font-size: .8em;
    margin-top: -6px;
    width: 100%;
    margin-bottom: 40px;
}
.title-info-forms {
    color: #FF40D7;
    font-size: 1.2em;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;
}

.border-none{
  border:none !important;
}

.title-agregar-perfil {
    font-size: 1em;
    color: #FF40D7;
    font-weight: bold;
}

.subtitle-agregar-perfil {
    font-size: 1em;
    color: #FF40D7;
    /*margin-top: ;*/
}

.texto-imagen-perfil {
    font-size: .6em;
    color: #a2a2a2;
    margin-top: 0px;
}

.inputfile{
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  z-index: 100;
	cursor: pointer;
}

.titulo-superior {
    font-size: .9em;
    padding-top: 10px;
}
.radio > label {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
}
.checkbox .cr, .radio .cr {
    position: relative;
    display: inline-block;
    border: 2px solid #FF40D7;
    border-radius: .25em;
    width: 1em;
    height: 1em;
    margin-left: auto;
    vertical-align: middle;
    font-size: 16px;
}


input[type='radio'] {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  outline: none;
  /*box-shadow: 0 0 0 2px gray;*/
}

input[type='radio']:checked {
  /*box-shadow: 0 0 0 2px #FF40D7;*/
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  margin: 2px 2px;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  background: #FF40D7;
}

.role {
  margin-right: 80px;
  margin-left: 20px;
  font-weight: normal;
}

.checkbox label {
  margin-bottom: 20px !important;
}

.roles {
  margin-bottom: 40px;
}

.titulo-radio-button-empresa{
    font-family: "Roboto","Arial","sans-serif" !important;
    font-size: 0.875rem !important;
    color: #506883;
    font-weight: 400 !important;
}

/*form input[type="password"],
form input[type="text"],
form input[type="number"],
form input[type="tel"],
form input[type="date"],
form input[type="email"] {
	 	border: 1px solid #ced4da;
    font-family: "Roboto","Arial","sans-serif", !important;
    position: relative !important;
    background-color: #F5F5F5 !important;
    padding: 8px 20px 8px 20px !important;
    z-index: 1 !important;
    margin-bottom: 0 !important;
}*/

/* form input[type="text"]:focus{
	border: 0 !important;
} */

.new-input {
    width: 100%;
    background: transparent !important;
    background-color: transparent;
    background-color: transparent !important;
		border: none !important;
    border-bottom: 1px solid #333 !important;
    color: #506883;
    box-shadow: none;
}

.input-group2 input {
    padding-left: 0 !important;
    border: 0 !important;
    background: transparent !important;
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
}

button,
.btn {
    font-weight: bold;
}

.btn:hover {
    color: white !important;
    text-decoration: none;
}

.btn{
		border-radius: 22px !important;
		font-weight: bold !important;
		border: none!important;
    color: white;
    text-decoration: none;
    font-weight: bold;
    min-width: 130px;
}

.cursor-pointer{
	cursor: pointer;
}
.cursor-pointer i{
	font-size: 20px;
}

.title-generico {
  font-size: 1.6rem;
  font-weight: bold;
  color: #000E4D;
  line-height: 1.1 !important;
}
.ico-generico{
	font-size: 50px;
	color: #FF40D7;
	font-weight: bold;
}

.form-control::placeholder {
	color: #ccc;
  font-size: 12px !important;
}

select{
	color: #aaa !important;
  font-size: 12px !important;
}
.btn-ico  i{
	font-size: 40px;
	color: #000E4D;
}
.border-normal{
	border:solid 2px #ddd !important;
}
.border-rosa{
	border:solid 2px #FF40D7 !important;
}

.text-rosa{
	color:#FF40D7 !important;
}

.text-gray{
	color: #aaa;
}

.btn-gray{
	border-radius: 22px !important;
  background: #ccc !important;
  font-weight: bold !important;
  border: none !important;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.btn-gray2{
	border-radius: 22px !important;
  background: #ccc !important;
  font-weight: bold !important;
  border: none !important;
  color: #999;
  text-decoration: none;
  font-weight: bold;
}
.Contenido-Home{
	/*min-height: 640px;*/
  position: relative;
}
.listados .card {
  border-radius: 1.25rem !important;
}

.listados .card-header {
    background-color: #000E4D !important;
    border-bottom:none;
}

.listados .card-header .text-title {
  font-weight: bold;
}

.listados .card-header:first-child {
    border-radius: calc(1.25rem - 1px) calc(1.25rem - 1px) 0 0;
}
.border-bottom-rosa-x3{
	border-bottom: 3px solid #FF40D7 !important;
}

.dashed {
	border: dashed 2px #ccc !important;
}

.btn2 {
    border-radius: 5px !important;
    font-weight: bold !important;
		padding: 0.75rem;
}

.btn-primary2{
	background: #FF40D7 !important;
	color: #fff;
}
.btn-primary {
  color: #fff;
  background: #FF40D7 !important;
}
.x4{
	font-size: 22px;
}

.x3{
	font-size: 18px;
}

.x2{
	font-size: 14px;
}

.x1{
	font-size: 12px;
}

.btn-outline-primary2{
	border: solid 2px  #FF40D7 !important;
	color: #FF40D7 !important;
}

.btn-outline-primary2:hover{
	color: #FF40D7 !important;
}

.text-red{
	color: red;
}

.text-green{
	color: green;
}

.input-group-2 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
		border-bottom: solid #ccc 1px;
}

.form-control-filter{
	border-radius: 0 !important;
  border: none;
	font-size: 12px;
	width: 80%;
	padding: 10px;
}

.form-control-filter:focus{
	border: 0px solid red !important;
	outline: none !important;
}


.input-group-2 .input-group-prepend .input-group-text {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    border-radius: 0rem !important;
}

.x5{
	font-size: 150%;
}

.pagination.pagination-table .page-link{
	background-color: #FF40D7 !important;
	border: solid #FF20D7 1px !important;
	border-radius: 2rem !important;
	padding: .2rem .5rem;
}

.Container-observaciones{
  margin-top: 10px;
  width: 100% !important;
  background: #F5F5F5;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-radius: 1px solid #969696;
}
.container-input-observaciones{
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
}
.container-input-observaciones>textarea{
  width: 100%;
  min-height: 100px;
  border:none;
  border-radius: 4px 4px 0 0 !important;
  padding: 20px;
  background: #FBFCFC !important;
}
textarea:focus, input:focus, input[type]:focus{
  box-shadow: none;
  outline: 0 none;
}
.opciones-Observaciones{
  padding: 4px 40px;
  font-size: 1.4rem;
  color: #6B6B6B;
  margin-bottom: 30px;
}
.comentario-observacion{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  margin-bottom: 10px;
}
.img-comentario{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.img-comentario>img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.texto-observacion{
  width: calc(90% - 70px) !important;
  font-size: 0.8rem;
}
.acciones-comentarios{
  width: calc(10%) !important;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF40D7;
}
.select-aprobar-cuenta{
  width: 100%;
  border: 1px solid rgb(175, 175, 175);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
}

.text-bold{font-weight: bold !important;}

#vertical{
  min-height: 720px;
}

.table thead th {
  color: #FF40D7;
  border: 0 !important;
}

.bg-login{
  background-color: #0D134E;
  min-height: 100vh;
  background-image: url(./assets/images/fondo-login.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.card-graficos-2{
  height: 400px;
}

.card-graficos-2 {
    background: white;
    padding: 30px;
    margin-top: 20px;
    border-radius: 14px;
    max-height: 26rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.monto-bolsillo{
  font-size: 10px;
  padding: 0 10px ;
  background-color: #FF40D7;
  color: #fff;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
.icon-invertido{
  transform: rotate(0.5turn);
}

.icon-down{
  transform: rotate(0.25turn);
}
.icon-up{
  transform: rotate(0.75turn);
}
.OptionIndicator{
  background-color: #0D154D !important;
  color: #FFF;
}

#vertical .navbar-default .ttmenu-content .row-mega-menu ul {
  padding: 0;
}

@media (max-width: 480px) {
  .Contenido-Home {
    margin-top: 20px;
    padding-left: 5px !important;
    padding-top: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 50px !important;
  }
  .title-form {
    font-size: 1.0rem;
  }
  .container-pass {
    margin-top: 16px;
  }
  .container-formulario {
    min-height:inherit;
    padding: 30px 20px;
  }
  .nav-item,
  .nav-item a,
  .nav-item a:link,
  .nav-item a:hover,
  .nav-item a:focus{
    font-size: 90%;
    /*padding: 2px 10px !important;*/
  }

  #vertical .navbar-default .navbar-nav li .vertical-menu {
      position: absolute;
      left: 90% !important;
      top: 0;
  }

  .sidebar{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1000;
  }

  .OptionCard {
    min-height: 38px !important;
    min-width: 6rem !important;
  }

  .items-verificacion.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .menu-bottom-transferir{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color:#0D154D;
    padding: 10px 5px;
  }

  .menu-bottom-transferir a:link{
    text-decoration:none;
  }

  .btn-ico i {
    font-size: 20px !important;
  }



}
